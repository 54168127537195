// extracted by mini-css-extract-plugin
export var bgGrey100 = "ReviewSlider-module--bg-grey-100--d679d";
export var bgGrey150 = "ReviewSlider-module--bg-grey-150--d27ff";
export var bgGrey200 = "ReviewSlider-module--bg-grey-200--26942";
export var bgGrey300 = "ReviewSlider-module--bg-grey-300--30e50";
export var bgGrey400 = "ReviewSlider-module--bg-grey-400--ef68a";
export var bgGrey500 = "ReviewSlider-module--bg-grey-500--c838f";
export var bgGrey600 = "ReviewSlider-module--bg-grey-600--7c25b";
export var bgGrey700 = "ReviewSlider-module--bg-grey-700--40781";
export var bgGrey800 = "ReviewSlider-module--bg-grey-800--1fcd5";
export var bgGrey900 = "ReviewSlider-module--bg-grey-900--4c808";
export var innerWrapper = "ReviewSlider-module--innerWrapper--4ec55";
export var sectionCls = "ReviewSlider-module--sectionCls--f30f9";
export var textGrey100 = "ReviewSlider-module--text-grey-100--d1349";
export var textGrey150 = "ReviewSlider-module--text-grey-150--071dd";
export var textGrey200 = "ReviewSlider-module--text-grey-200--e4074";
export var textGrey300 = "ReviewSlider-module--text-grey-300--c6f07";
export var textGrey400 = "ReviewSlider-module--text-grey-400--92f37";
export var textGrey500 = "ReviewSlider-module--text-grey-500--d86a2";
export var textGrey600 = "ReviewSlider-module--text-grey-600--cc444";
export var textGrey700 = "ReviewSlider-module--text-grey-700--15f28";
export var textGrey800 = "ReviewSlider-module--text-grey-800--a9c0b";
export var textGrey900 = "ReviewSlider-module--text-grey-900--6c6c3";
export var wrapper = "ReviewSlider-module--wrapper--b1993";