import React, { Fragment, useRef, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { v4 as uuidv4 } from "uuid"

import { Section, Container, SectionHeading } from "../../UI/Common"

import Card from "./Card"
import { PrevButton, NextButton } from "../../UI/Slider"

import * as style from "./ReviewSlider.module.scss"

const ReviewSlider = ({ sectionHeading, googleReviewData, config }) => {
  const { padding } = config || {}
  const [slide, setSlide] = useState(0)

  const {
    allGooglePlacesReview: { nodes: reviews },
  } = googleReviewData

  const slider = useRef(null)

  const settings = {
    appendDots: dots => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    dots: false,
    arrows: false,
    className: "variable-width",
    variableWidth: true,
    adaptiveHeight: true,
    centerMode: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Section className={style.sectionCls} padding={padding}>
      <>
        <Container>
          <SectionHeading text={sectionHeading} />
        </Container>
        <div className={style.wrapper}>
          <div className={style.innerWrapper}>
            <>
              <div>
                <PrevButton ref={slider} show={[slide, setSlide]} />
              </div>
              <div>
                <NextButton ref={slider} show={[slide, setSlide]} />
              </div>
            </>

            <Slider ref={slider} {...settings}>
              {reviews.map(review => {
                const {
                  author_name: author,
                  text,
                  rating: reviewRating,
                  relative_time_description: when,
                } = review

                return (
                  <Fragment key={uuidv4()}>
                    <Card
                      author={author}
                      when={when}
                      text={text}
                      rating={reviewRating}
                    />
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        </div>
      </>
    </Section>
  )
}

export default ReviewSlider
