// extracted by mini-css-extract-plugin
export var bgGrey100 = "Card-module--bg-grey-100--be586";
export var bgGrey150 = "Card-module--bg-grey-150--7b2d2";
export var bgGrey200 = "Card-module--bg-grey-200--12c3b";
export var bgGrey300 = "Card-module--bg-grey-300--c8d1e";
export var bgGrey400 = "Card-module--bg-grey-400--d39bc";
export var bgGrey500 = "Card-module--bg-grey-500--d6155";
export var bgGrey600 = "Card-module--bg-grey-600--a896e";
export var bgGrey700 = "Card-module--bg-grey-700--57ac4";
export var bgGrey800 = "Card-module--bg-grey-800--83fd7";
export var bgGrey900 = "Card-module--bg-grey-900--0e296";
export var cardWrapperCls = "Card-module--cardWrapperCls--910fc";
export var modalStarsCls = "Card-module--modalStarsCls--fa910";
export var rated1Cls = "Card-module--rated1Cls--28ad0";
export var rated2Cls = "Card-module--rated2Cls--24e21";
export var rated3Cls = "Card-module--rated3Cls--b4335";
export var rated4Cls = "Card-module--rated4Cls--120b9";
export var rated5Cls = "Card-module--rated5Cls--8fd08";
export var textGrey100 = "Card-module--text-grey-100--1439a";
export var textGrey150 = "Card-module--text-grey-150--ddf79";
export var textGrey200 = "Card-module--text-grey-200--0ac86";
export var textGrey300 = "Card-module--text-grey-300--47d91";
export var textGrey400 = "Card-module--text-grey-400--b3837";
export var textGrey500 = "Card-module--text-grey-500--5ae6d";
export var textGrey600 = "Card-module--text-grey-600--1b9f9";
export var textGrey700 = "Card-module--text-grey-700--cef6c";
export var textGrey800 = "Card-module--text-grey-800--ebcbd";
export var textGrey900 = "Card-module--text-grey-900--4548d";