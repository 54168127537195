import React from "react"
import { v4 as uuidv4 } from "uuid"

import { ModalPortal, ToggleModal } from "../../../portals/Modal.portal"

import {
  cardWrapperCls,
  rated1Cls,
  rated3Cls,
  rated2Cls,
  rated4Cls,
  rated5Cls,
  modalStarsCls,
} from "./Card.module.scss"
import GoogleInfo from "./GoogleInfo"

const Card = ({ author, when, text, rating }) => {
  const truncatedText = text.length > 295 ? text.slice(0, 295) : null

  const ratingStyles = {
    1: rated1Cls,
    2: rated2Cls,
    3: rated3Cls,
    4: rated4Cls,
    5: rated5Cls,
  }
  return (
    <div className={cardWrapperCls}>
      <div>
        <div className="font-weight-bold">{author}</div>
        <div className="d-flex align-items-center">
          <span
            className={ratingStyles[rating]}
            aria-label={`${process.env.ORG_OFFICIAL_NAME} is rated ${rating} out of 5 on Google Reviews.`}
          />{" "}
          <span className="mt-1 ml-1 text-greyed">{when}</span>
        </div>
      </div>
      <div className="text-greyed">
        <p className="py-3">
          {truncatedText ? (
            <>
              <span>{truncatedText}… </span>
              <ToggleModal
                toggle={show => (
                  <a
                    className="text-decoration text-dark"
                    role="button"
                    onClick={show}>
                    Read more
                  </a>
                )}
                content={hide => {
                  return (
                    <ModalPortal hide={hide} size="xl" centered={true}>
                      <div className="px-5 pb-4">
                        <div className="mb-5">
                          <div className="font-weight-bold">{author}</div>
                          <div className="d-flex align-items-center">
                            <span
                              className={`${ratingStyles[rating]} ${modalStarsCls}`}
                              aria-label={`${process.env.ORG_OFFICIAL_NAME} is rated ${rating} out of 5 on Google Reviews.`}
                            />{" "}
                            <span className="mt-1 ml-1 text-greyed">
                              {when}
                            </span>
                          </div>
                        </div>

                        <div>
                          {text.split("\n").map(line => {
                            return <p key={uuidv4()}>{line}</p>
                          })}
                        </div>

                        <GoogleInfo className="mt-5" />
                      </div>
                    </ModalPortal>
                  )
                }}
              />
            </>
          ) : (
            text
          )}
        </p>
      </div>
    </div>
  )
}

export default Card
