// extracted by mini-css-extract-plugin
export var bgGrey100 = "GoogleInfo-module--bg-grey-100--93a9f";
export var bgGrey150 = "GoogleInfo-module--bg-grey-150--4c71a";
export var bgGrey200 = "GoogleInfo-module--bg-grey-200--46b8d";
export var bgGrey300 = "GoogleInfo-module--bg-grey-300--3fd52";
export var bgGrey400 = "GoogleInfo-module--bg-grey-400--9f2cf";
export var bgGrey500 = "GoogleInfo-module--bg-grey-500--cb7dc";
export var bgGrey600 = "GoogleInfo-module--bg-grey-600--67209";
export var bgGrey700 = "GoogleInfo-module--bg-grey-700--07fc5";
export var bgGrey800 = "GoogleInfo-module--bg-grey-800--09750";
export var bgGrey900 = "GoogleInfo-module--bg-grey-900--2f4aa";
export var googleLogoContainerCls = "GoogleInfo-module--googleLogoContainerCls--d423b";
export var googleLogoLinkCls = "GoogleInfo-module--googleLogoLinkCls--5cf08";
export var textGrey100 = "GoogleInfo-module--text-grey-100--1a479";
export var textGrey150 = "GoogleInfo-module--text-grey-150--92935";
export var textGrey200 = "GoogleInfo-module--text-grey-200--8d479";
export var textGrey300 = "GoogleInfo-module--text-grey-300--06ba9";
export var textGrey400 = "GoogleInfo-module--text-grey-400--d5559";
export var textGrey500 = "GoogleInfo-module--text-grey-500--26ad9";
export var textGrey600 = "GoogleInfo-module--text-grey-600--259e5";
export var textGrey700 = "GoogleInfo-module--text-grey-700--e59ca";
export var textGrey800 = "GoogleInfo-module--text-grey-800--bed3f";
export var textGrey900 = "GoogleInfo-module--text-grey-900--ffbaa";