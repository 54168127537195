import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { googleLogoContainerCls, googleLogoLinkCls } from "./GoogleInfo.module.scss"

const GoogleInfo = ({ className }) => (
  <div className={`${googleLogoContainerCls} ${className}`}>
    <a
      className={googleLogoLinkCls}
      href={process.env.GOOGLE_REVIEWS_URL}
      target="_blank"
      rel="noopener noreferrer">
      <StaticImage
        src="./assets/logo-google-reviews.png"
        alt="Google Reviews logo"
        placeholder="none"
        width={100}
      />
    </a>
    <p className="ml-3 mb-0 text-greyed">
      Review provided by Google. ©2021 Google LLC All rights reserved. Google
      and the Google logo are registered trademarks of Google LLC.
    </p>
  </div>
)

export default GoogleInfo
