import { graphql } from "gatsby"
import React from "react"

import ReviewSliderComponent from "./ReviewSlider"

export const fragment = graphql`
  fragment ReviewSliderFragment on WpPage_Flexlayouts_FlexibleLayouts_ReviewSlider {
    sectionHeading
    button {
      url
      title
      target
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFReviewSlider = ({
  googleReviewData,
  sectionHeading,
  button,
  config,
}) => {

  return (
    <ReviewSliderComponent
      sectionHeading={sectionHeading}
      button={button}
      googleReviewData={googleReviewData}
      config={config}
    />
  )
}
